import React from "react"
import { Link,graphql } from "gatsby"

import Layout from "../../../components/layout"
import HeaderLarge from "../../../components/headerLarge"
import SEO from "../../../components/seo"
import GuideStarComponent from "../../../components/guideStarComponent2020"
import InsurerListPerCategory from "../../../components/insurerListPerCategory"

export const query = graphql`
{           
  site {
    siteMetadata {
      title,
      year
    }
  },
  allTurbatforsikring2020Csv(sort: {fields: Defaqto_Stjerne, order: DESC}) {
    edges {
      node{
        Provider
        Product
        Defaqto_Stjerne
      }
    }
  }
  allProviders2020: allTurbatforsikring2020Csv(sort:{fields: Provider, order: ASC}){
    edges{
      node{
        Provider
      }
    }
  }
}
`

const IndexPage = ({data}) => {

  const year = data.site.siteMetadata.year

  return(
    <Layout>
      <SEO title={`De beste turbåtforsikringene i ${year} | Komplett oversikt`}
           description={"Best i test turbåtforsikring. Oversikt med vurderinger av de beste forsikringene til båt for turbruk i det norske markedet - forsikringene med best vilkår og som gir de beste dekningene."}
           schema={`
                {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Bestforsikring.no",
                  "item": "https://bestforsikring.no"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Båtforsikring",
                  "item": "https://bestforsikring.no/bat"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Turbåt",
                  "item": "https://bestforsikring.no/bat/turbat"
                }]
              }
           `}/>
      <HeaderLarge
        siteSection={"bat"}
        Entity={"turbåt"}
        Year={year}
        Launched={true}
        siteHeading={<>De BESTE <span style={{fontSize:"92%"}}>forsikringene til turbåt</span></>}
        siteDescription={<><span>Vi rangerer de beste turbåtforsikringene i Norge basert på fakta om </span><u>kvalitet på vilkårene</u><span> - ikke subjektive meninger.</span></>}/>

      <section id="section-content" className="content page-33 moto-section" data-widget="section" data-container="section">

        <div className="moto-widget moto-widget-row row-fixed moto-bg-color5_5 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled>
          <div className="container-fluid">
            <div className="row" data-container="container">
              <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                  <div className="moto-widget-text-content moto-widget-text-editable">
                    <h1 className="moto-text_system_12" style={{textAlign: 'center'}}>Dette er de beste turbåtforsikringene i {data.site.siteMetadata.year}</h1>
                    <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>
                    <p className="moto-text_system_7" style={{textAlign: 'center'}}>- basert på fakta om 36 egenskaper ved hver enkelt forsikring</p>
                    <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>
                    <p className="moto-text_system_8" style={{textAlign: 'center'}}>
                      Her er de <strong>beste båtforsikringene</strong> som tilbys i det norske markedet i {data.site.siteMetadata.year} - det vil si de som har fått <strong>5 Defaqto Stjerner</strong> og er <strong>best i test båtforsikringer</strong>:
                    </p>

                    <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>

                    <div className={"container-fluid"}>
                      <div className={"row"} data-container={"container"}>
                        {data.allTurbatforsikring2020Csv.edges.map(({node: forsikring}) => (
                          <>
                            {
                              forsikring.Defaqto_Stjerne.toString() === "5" &&
                              <GuideStarComponent key={forsikring.Provider+forsikring.Product+forsikring.Defaqto_Stjerne} Section={"bat/turbat"} Provider={forsikring.Provider} Stars={forsikring.Defaqto_Stjerne} Product={forsikring.Product} Year={"2020"}/>
                            }
                          </>
                        ))}
                      </div>
                    </div>

                    <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>

                    <div data-widget-id="wid__button__5c9764a4e3084" className="moto-widget moto-widget-button moto-preset-4 moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  " data-widget="button">
                      <Link style={{marginLeft:20, marginRight:20, marginTop:20}} to={"/bat/turbat/sammenlign"} data-action="page" className="moto-widget-button-link moto-size-medium moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">Vis hele listen</span></Link>

                      <Link style={{marginLeft:20, marginRight:20, marginTop:20}} to={"/bat/turbat/"+year+"/guide"} data-action="page" className="moto-widget-button-link moto-size-medium moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">Les guiden vår</span></Link>
                    </div>

                    <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>

                    <p className="moto-text_normal" style={{textAlign: 'center'}}>
                      Lurer du på hvor mange Defaqto stjerner som er godt nok for ditt forsikringsbehov? Ta en titt på <Link to={"/bat/turbat/"+year+"/guide"}>forsikringsguiden vår for turbåt</Link>.
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="moto-widget moto-widget-row row-fixed moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled><div className="container-fluid"><div className="row" data-container="container"><div className="moto-cell col-sm-12 moto-widget moto-widget-row__column" data-container="container" data-widget="row.column" style={{}} data-bg-position="left top"><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-animation data-draggable-disabled data-visible-on="mobile-v">
          <div className="moto-widget-text-content moto-widget-text-editable"><h2 style={{textAlign: 'center'}} className="moto-text_system_5">Vi har vurdert forsikringer til turbåt fra følgende selskaper:</h2></div>
        </div><div className="moto-widget moto-widget-row moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="saaa" style={{}} data-bg-position="left top">
          <div className="container-fluid">
            <div className="row" data-container="container">
              <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
              </div>
            </div>
          </div>
        </div><div className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}} data-bg-position="left top" data-draggable-disabled>
          <div className="container-fluid">
            <div className="row" data-container="container">
              <div className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
                  <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal companyStrong">Vi har som mål å dekke alle selskapene som tilbyr forsikringsprodukter for private båter i Norge.</p></div>
                </div></div><div className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                <div className="moto-widget-text-content moto-widget-text-editable">
                  <p className="moto-text_normal">
                    <InsurerListPerCategory objList={data.allProviders2020}/>
                  </p>

                </div>
              </div></div>
            </div>
          </div>
        </div></div></div></div></div>
      </section>
    </Layout>
  )
}

export default IndexPage

function slugifier(input) {

  let slugString = input;

  return slugString
  //.toLowerCase()
    .replace(/æ/g,'ae')
    .replace(/ø/g,'o')
    .replace(/å/g,'aa')
    .replace(/&/g,'og')
    .replace(/[^a-zA-Z0-9\/]+/g, '-')
    .replace(/\/-/g,'/')
    .slice(0, 96)                                             // Setting max length to 96
    .replace(/-$/g,'')
}